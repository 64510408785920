<template>
    <div class="mb-0">
      <card class="mb-0">
        <h5 slot="header" class="title mb-0">{{community.id ? 'Update' : 'Add'}} Community</h5>
        <v-form
            ref="form"
            v-model="parentForm"
            @submit.enter.prevent
        >
       
        <div class="row ma-0 pa-0">
          
          <div class="col-md-6 pl-0">
            <v-text-field
              placeholder="Community Name"
              v-model="community.name"
              :rules="nameRules"
              outlined
              label="Community Name"
            >
            </v-text-field>
          </div>
          <div class="col-md-6 pr-0">
            <v-select
                v-model="community.cityId"
                label="Select City"
                :rules="cityRules"
                item-text="name"
                item-value="id"
                outlined
                clearable
                attach
                :menu-props="{ offsetY: true,maxHeight:160 }"
                :items="cities"
            />
          </div>
        </div>
        <v-row class="pb-5 px-3">
            <v-col cols="auto" class="d-flex ml-auto pa-0 pt-2">
        <v-btn
            class="body-2 font-weight-bold mr-5 cancel-color"
            @click="cancel()"
        >
            Cancel
        </v-btn>
        <v-btn
            class="body-2 font-weight-bold save-color"
            :loading="editloading"
            :disabled="!parentForm"
            :dark="parentForm"
            @click="updateInfo()"
        >
            {{community.id  ? 'update' : 'save'}}
        </v-btn>
    </v-col>
    </v-row>
    </v-form>
      </card>
    </div>
  </template>
  <script>
  import commonService from '@/services/commonFunction.js'
  import apiService from '@/services/apiServices.js'
  export default {
    name:'community',
    props: {
      community: {
        type: Object,
        default: () => {
          return {};
        }
      },
      cities:{
        type: Array,
        default:()=> []
      },
      refreshData:{
        type: Function,
        default : () => null
      },
      cancel:{
        type: Function,
        default : () => null
      }
    },
    data(){
      return {
        parentForm:null,
        editloading:false,
        nameRules:[
            v => !!v || 'Name is required',
            v => !v || !!v.trim() || 'Name can not be blank'
        ],
        cityRules : [
            v => !!v || 'City is required',
        ]
      }
    },
    methods:{
        async updateInfo(){
            let payload = {
                community: { 
                    name: this.community.name,
                    cityId: this.community.cityId
                }
            }
            this.editloading = true
            let res;
            try{
                if(this.community.id){
                    res = await apiService.updateCommunity(payload,this.community.id)        
                }
                else 
                    res = await apiService.addCommunity(payload)      

                commonService.alert(`Community ${this.community.id ? 'updated' : 'added'} successfully`, 'Success')
                this.editloading = false;
                this.refreshData();
                this.cancel();
            }
            catch(err){
                console.log(err);
                this.editloading = false
            }
        
      },
     
  
    }
  };
  </script>
  <style scoped>
  .cancel-color{
        background-color: grey !important;
    }
    .save-color{
        background-color: #2e4250 !important
    }
  </style>
  
<template>
  <div class="mb-0">
    <card class="mb-0">
      <h5 slot="header" class="title mb-0">{{user.id ? 'Update' : 'Add'}} User</h5>
      <v-form
          ref="form"
          v-model="parentForm"
          @submit.enter.prevent
      >
     
      <div class="row ma-0 pa-0">
        
        <div class="col-md-6 pl-0">
          <v-text-field
            placeholder="Name"
            v-model="user.name"
            :rules="nameRules"
            outlined
            label="Name"
          >
          </v-text-field>
        </div>
        <div class="col-md-6 pr-0">
          <v-text-field
            placeholder="Email"
            v-model="user.email"
            :rules="emailRules"
            outlined
            label="Email"
          >
          </v-text-field>
        </div>
      </div>
        <div class="row ma-0 pa-0">
        <div class="col-md-6 pl-0">
            <v-select
                v-model="user.communityId"
                label="Select Community"
                :rules="communityRules"
                item-text="name"
                item-value="id"
                outlined
                clearable
                attach
                :menu-props="{ offsetY: true,maxHeight:160 }"
                :items="community"
            />
          </div>

          <div class="col-md-6 pl-0">
            <v-select
                v-model="user.type"
                label="Type"
                :rules="typeRules"
                item-text="name"
                item-value="id"
                outlined
                clearable
                attach
                :menu-props="{ offsetY: true,maxHeight:160 }"
                :items="[{name:'USER',id:'USR'},{name:'ADMIN',id:'ADM'}]"
            />
          </div>
      </div>
      <div class="col-md-6 pl-0" v-if="!user.id">
          <v-text-field
            placeholder="password"
            v-model="user.pwd"
            outlined
            label="Password"
            type="password"
          >
          </v-text-field>
        </div>
      <v-row class="pb-5 px-3">
          <v-col cols="auto" class="d-flex ml-auto pa-0 pt-2">
      <v-btn
          class="body-2 font-weight-bold mr-5 cancel-color"
          @click="cancel()"
      >
          Cancel
      </v-btn>
      <v-btn
          class="body-2 font-weight-bold save-color"
          :loading="editloading"
          :disabled="!parentForm"
          :dark="parentForm"
          @click="updateInfo()"
      >
          {{user.id  ? 'update' : 'save'}}
      </v-btn>
  </v-col>
  </v-row>
  </v-form>
    </card>
  </div>
</template>
<script>
import commonService from '@/services/commonFunction.js'
import apiService from '@/services/apiServices.js'
export default {
  name:'user',
  props: {
    user: {
      type: Object,
      default: () => {
        return {};
      }
    },
    community:{
      type: Array,
      default: () => {
        return [];
      }
    },
    refreshData:{
      type: Function,
      default : () => null
    },
    cancel:{
      type: Function,
      default : () => null
    }
  },
  data(){
    return {
      parentForm:null,
      editloading:false,
      nameRules:[
          v => !!v || 'Name is required',
          v => !v || !!v.trim() || 'Name can not be blank'
      ],
      emailRules: [
        v => !!v || 'Email is required',
        v => !v || !!v.trim() || 'Email cannot be blank',
        v => !v || /^[a-zA-Z0-9%+=._-]+@(([a-zA-Z0-9]|-)+\.)+([a-zA-Z])+$/.test(v) || 'Invalid email.',
      ],
      communityRules :[
          v => !!v || 'Community is required'
      ],
      typeRules : [
        v => !!v || 'Type is required'
      ]
    }
  },
  methods:{
      async updateInfo(){
          let payload = {
              user: { 
                name : this.user.name,
                email : this.user.email,
                communityId : this.user.communityId,
                type : this.user.type
              }
          }
          this.editloading = true
          let res;
          try{
              if(this.user.id){
                  res = await apiService.updateUser(payload,this.user.id)        
              }
              else {
                  payload.user.email_valid = 1;
                  res = await apiService.addUser(payload)      
              }
              commonService.alert(`User ${this.user.id ? 'updated' : 'added'} successfully`, 'Success')
              this.editloading = false
              this.refreshData();
              this.cancel();
          }
          catch(err){
              console.log(err);
              this.editloading = false
          }
      
    }
  }
};
</script>
<style scoped>
.cancel-color{
      background-color: grey !important;
  }
  .save-color{
      background-color: #2e4250 !important
  }
</style>

